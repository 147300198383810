import {
  GateIoIcon,
  KucoinIcon,
  MexcIcon,
  UniswapIcon
} from 'shared/icons/about-page/exchanges'

export const TGE_EXCHANGES = [
  {
    icon: KucoinIcon,
    disabled: false,
    href: 'https://www.kucoin.com/trade/XYRO-USDT'
  },
  {
    icon: GateIoIcon,
    disabled: false,
    href: 'https://www.gate.io/trade/XYRO_USDT'
  },
  {
    icon: MexcIcon,
    disabled: false,
    href: 'https://www.mexc.com/exchange/XYRO_USDT'
  },
  {
    icon: UniswapIcon,
    disabled: false,
    href: 'https://app.uniswap.org/explore/tokens/ethereum/0x4eddb15a0abfa2c349e8065af9214e942d9a6d36'
  }
] as const
