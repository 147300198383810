export enum RouterPathes {
  home = '/',

  // MODES
  memeWars = '/meme-wars',
  oneVsOne = '/one-vs-one',
  setups = '/setups',
  bullsEye = '/bulls-eye',
  upDown = '/up-down',
  x1000 = '/x1000',

  // USER
  priceFormulation = '/price-formulation',
  fundingPayments = '/funding-payments',
  settings = '/settings',
  profile = '/profile',
  transactions = '/transactions',
  notifications = '/notifications',
  passwordRecovery = '/password-recovery',
  rewards = '/rewards',
  referrals = '/referrals',

  // MISC
  help = '/help',
  termsAndConditions = '/terms-and-conditions',
  privacyPolicy = '/privacy-policy',
  termsOfService = '/terms-of-service',
  amlPolicy = '/aml-policy',
  about = '/about',
  buyback = '/token',
  buybackBridge = '/bridge',
  buybackSwap = '/trade',
  buybackHolders = '/holders',

  // ONBORADING
  onboarding = '/onboarding',
  onboardingUpDown = '/onboarding/up-down',
  onboardingBullsEye = '/onboarding/bulls-eye',
  onboardingSetups = '/onboarding/setups',
  onboardingOneVsOne = '/onboarding/one-vs-one',
  onboardingMemeWars = '/onboarding/meme-wars',
  onboardingTreasure = '/onboarding/treasure',
  onboardingReferralProgram = '/onboarding/referral-program',
  onboardingRewardsSystem = '/onboarding/rewards-system',

  // OTHER
  oAuth = '/oauth',
  unlogged = '/unlogged',
  dev = '/very-secret-dev-page-for-very-secret-people-who-are-very-secretly-debugging'
}
