import React from 'react'
import { Box, Flex, Grid, Text } from '@radix-ui/themes'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { MS_IN_MIN } from 'shared/constants'
import { usePaymentsStartDate } from 'shared/hooks/use-payments-start-date'
import { useResponsive } from 'shared/hooks/use-responsive'
import { bannerTgeXyro, bannerTgeXyroMobile } from 'shared/images'
import { TGE_EXCHANGES } from './constants'
import { LiveBox } from './live-box'
import { TgeExchangeButton } from './tge-exchange-button'
import styles from './tge-start-tab.module.scss'

export const TgeStartTab: React.FC = () => {
  const [isNotDesktop] = useResponsive(['xs', 'sm', 'md', 'lg'])

  const endDate = new Date(Date.UTC(2025, 0, 23, 10, 0, 0))
  const startDate = new Date(Date.UTC(2025, 0, 14, 0, 0, 0))

  const { paymentsWillStartPercentage, paymentsWillStart, remainingTimeMs } =
    usePaymentsStartDate(startDate, endDate)

  const isLive = remainingTimeMs <= MS_IN_MIN * 61

  return (
    <Flex
      className={styles.tgeStartTabWrapper}
      align={'center'}
      mb={'3'}
      gap={{ initial: '6', sm: '6' }}
      justify={'between'}
      direction={{ initial: 'column', lg: 'row' }}
    >
      <div className={styles.xyroImageBlurBackground} />

      <Flex
        width={'100%'}
        justify={{ initial: 'center', lg: 'between' }}
        align={'center'}
        gap={{ initial: '5', lg: '0' }}
        direction={{ initial: 'column', lg: 'row' }}
      >
        <img
          src={isNotDesktop ? bannerTgeXyroMobile : bannerTgeXyro}
          alt='bannerTgeXyro'
          className={styles.xyroImage}
        />

        <Flex
          direction={'column'}
          gap={{ initial: '0', lg: '1' }}
        >
          {isLive ?
            <Text
              className={styles.tgeWillStartText}
              size={{ initial: '7', lg: '8' }}
              weight={'bold'}
            >
              GET $XYRO NOW
            </Text>
          : <>
              <Text
                className={styles.tgeWillStartText}
                size={{ initial: '6', lg: '5' }}
                weight={'bold'}
                align={{ initial: 'center', lg: 'left' }}
              >
                $XYRO TGE LAUNCHES
              </Text>

              <Text
                align={{ initial: 'center', lg: 'left' }}
                className={styles.tgeWillStartText}
                size={{ initial: '5', lg: '3' }}
              >
                January 23, 10 AM UTC
              </Text>
            </>
          }
        </Flex>

        <Grid
          className={styles.exchangeLinks}
          align={'center'}
          columns={'1fr 1fr'}
          rows={'1fr 1fr'}
          gap={'2'}
        >
          {TGE_EXCHANGES.map(({ icon, href, disabled }, index) => (
            <TgeExchangeButton
              key={index}
              icon={icon}
              href={href}
              disabled={isLive ? false : disabled}
            />
          ))}
        </Grid>

        {!isLive ?
          <Flex
            className={styles.countdownBlock}
            align={'center'}
            gap={'1'}
          >
            <Box
              className={styles.roundProgressBar}
              width={'3rem'}
              height={'3rem'}
            >
              <CircularProgressbar
                className={styles.timeframeCountdown}
                value={paymentsWillStartPercentage}
                maxValue={100}
                counterClockwise={true}
                strokeWidth={50}
                background={true}
                styles={progressBarStyles}
              />
            </Box>

            <Text
              className={styles.timerTimeText}
              size={{ initial: '3', lg: '4' }}
            >
              {paymentsWillStart}
            </Text>
          </Flex>
        : <LiveBox />}
      </Flex>
    </Flex>
  )
}

const progressBarStyles = buildStyles({
  pathColor: 'var(--c-white)',
  trailColor: 'var(--c-black)',
  backgroundColor: 'var(--c-white)',
  pathTransitionDuration: 0.3,
  strokeLinecap: 'butt'
})
