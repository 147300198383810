export const ASSET_PRICE_MULTIPLIER_OLD = 1e4
export const ASSET_PRICE_MULTIPLIER_NEW = 1e18

export const ASSET_PRICE_DIGITALS_OLD = 4
export const ASSET_PRICE_DIGITALS_NEW = 18

export const PREDICT_AMOUNT_MULTIPLIER_OLD = 1
export const PREDICT_AMOUNT_MULTIPLIER_NEW = 1e6

export const DEFAULT_CONTRACT_VERSION = 3
export const NEW_CONTRACT_VERSION = 3

export const XYRO_TOKEN_DECIMALS = 18
export const XYRO_TOKEN_MULTIPLIER = 1e18

export const USDT_DECIMALS = 6
