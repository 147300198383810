import { FC } from 'react'
import { RadixColorType } from 'shared/types'

interface Props {
  color?: RadixColorType | 'black' | 'white'
  size?: string
  className?: string
}
export const XyroToken: FC<Props> = ({ size = '3rem', className }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.99771 1.46965C4.3911 1.46965 1.46736 4.39339 1.46736 8C1.46736 11.6066 4.3911 14.5304 7.99771 14.5304C11.6043 14.5304 14.5281 11.6066 14.5281 8C14.5281 4.39339 11.6043 1.46965 7.99771 1.46965ZM11.2884 6.97395C11.7149 6.91745 11.9726 7.43145 11.6722 7.73942L6.62012 12.919C6.24915 13.2993 5.63939 12.8406 5.90193 12.3787L7.54479 9.48851C7.7045 9.20754 7.52708 8.85449 7.20632 8.81497L5.00189 8.54337C4.62122 8.49647 4.46742 8.02729 4.74644 7.76413L6.8148 5.81341L9.40703 3.49877C9.77172 3.17314 10.3223 3.57627 10.1221 4.02228L8.97209 6.58351C8.82674 6.90723 9.09311 7.26482 9.4449 7.21821L11.2884 6.97395Z'
        fill='#7ED3AF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.99771 1.46965C4.3911 1.46965 1.46736 4.39339 1.46736 8C1.46736 11.6066 4.3911 14.5304 7.99771 14.5304C11.6043 14.5304 14.5281 11.6066 14.5281 8C14.5281 4.39339 11.6043 1.46965 7.99771 1.46965ZM11.2884 6.97395C11.7149 6.91745 11.9726 7.43145 11.6722 7.73942L6.62012 12.919C6.24915 13.2993 5.63939 12.8406 5.90193 12.3787L7.54479 9.48851C7.7045 9.20754 7.52708 8.85449 7.20632 8.81497L5.00189 8.54337C4.62122 8.49647 4.46742 8.02729 4.74644 7.76413L6.8148 5.81341L9.40703 3.49877C9.77172 3.17314 10.3223 3.57627 10.1221 4.02228L8.97209 6.58351C8.82674 6.90723 9.09311 7.26482 9.4449 7.21821L11.2884 6.97395Z'
        fill='url(#paint0_linear_14889_366801)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_14889_366801'
          x1='9.68831'
          y1='14.3094'
          x2='6.13019'
          y2='1.03031'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3BC58B' />
          <stop
            offset='0.43'
            stopColor='#C4FFE6'
          />
          <stop
            offset='1'
            stopColor='#3BC58B'
          />
        </linearGradient>
      </defs>
    </svg>
  )
}
