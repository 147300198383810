import React from 'react'
import { Button } from '@radix-ui/themes'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import styles from './tge-start-tab.module.scss'

interface Props {
  href: string
  disabled: boolean
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}
export const TgeExchangeButton: React.FC<Props> = ({
  icon: Icon,
  disabled,
  href
}) => {
  return (
    <Link
      target={'_blank'}
      to={disabled ? '#' : href}
      className={classNames(styles.goToExchangeLink, {
        [styles.exchangeLinkDisabled]: disabled
      })}
    >
      <Button className={styles.goToExchangeBtn}>
        <Icon
          className={classNames({ [styles.exchangeIconEnabled]: !disabled })}
        />
      </Button>
    </Link>
  )
}
