import React from 'react'
import { Box, Flex, Text } from '@radix-ui/themes'
import styles from './tge-start-tab.module.scss'

export const LiveBox: React.FC = () => {
  return (
    <Flex
      className={styles.liveBox}
      align={'center'}
      gap={'4'}
    >
      <Flex
        className={styles.pulseDotWrapper}
        align={'center'}
        justify={'center'}
      >
        <Box className={styles.pulseDot} />
      </Flex>

      <Text
        className={'color-white'}
        size={'5'}
        weight={'bold'}
      >
        LIVE
      </Text>
    </Flex>
  )
}
